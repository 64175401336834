import template from 'lodash/template';

class Template {
   constructor() {
      this.cachedTemplates = {};
   }

   #getCompiledTemplate(str) {
      const compiled = template(str);
      return (data, asString) => {
         const html = compiled(data);
         return asString ? html : this.#htmlToDom(html);
      };
   }

   /**
    * Converts a string of HTML to a DOM element.
    *
    * Returns:
    *   - One element if passed something like "<div>...</div>"
    *   - Array of elements if passed "<a>...</a><a>...</a>"
    */
   #htmlToDom(html) {
      const children = $.parseHTML ? $.parseHTML(html) : new Element('div', { html }).getChildren();

      // If there is only one child, return that element instead of the array.
      return children.length === 1 ? children[0] : children;
   }

   /**
    * Returns a function(data, asString) that takes in an object and
    * produces some DOM elements (or a string if asString is set).
    */
   get(str) {
      if (!this.cachedTemplates[str]) {
         this.cachedTemplates[str] = this.#getCompiledTemplate(str);
      }
      return this.cachedTemplates[str];
   }
}

const templateInstance = new Template();
export default templateInstance;
